.bigbild {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
}

.bigbild-text {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2rem;
    color: #fff;
    font-size: 1.4rem;
}

.link-bereich {
    color: #fff;
}