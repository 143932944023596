.link-bereich {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding-top: 30px;

}

@media (max-width: 500px) {
    .link-bereich {
        display: block;
        padding-left: 10px;
        padding-right: 10px;
    }

    .link-bereich a {

        display: block;
        margin-bottom: 10px;

    }
}