.bloglink {
    color: #1c1c1c !important;
    font-size: 20px;
}

.bloglink:hover {
    color: #0052bd;
    font-weight: bold;

}

.links {
    text-decoration: none;
}

.space {
    width: 20px !important;
    height: 50vh !important;
    background-color: #fff;
}